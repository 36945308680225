import { MediaConditions } from '@/components/shared/media/image/Image.props';

// Image size constants
export const IMAGE_SIZES = {
  MOBILE_XS: 320,
  MOBILE_SM: 375,
  MOBILE_MD: 414,
  MOBILE_LG: 576,
  MOBILE_XL: 640,
  TABLET_XS: 750,
  TABLET_SM: 828,
  TABLET_MD: 960,
  TABLET_LG: 1024,
  DESKTOP_XS: 1280,
  DESKTOP_SM: 1366,
  DESKTOP_MD: 1440,
  DESKTOP_LG: 1600,
  DESKTOP_XL: 1920,
};

// Device size constants
export const DEVICE_SIZES = {
  MOBILE_XS: 320,
  MOBILE_SM: 390,
  MOBILE_MD: 480,
  MOBILE_LG: 576,
  TABLET_XS: 640,
  TABLET_SM: 768,
  TABLET_MD: 1024,
  DESKTOP_SM: 1280,
  DESKTOP_MD: 1440,
  DESKTOP_LG: 1600,
  DESKTOP_XL: 1920,
};

// Media queries for device sizes
export const MEDIA_QUERIES: { [key: string]: MediaConditions } = {
  // minWidth: 1920
  DESKTOP_XL: {
    minWidth: DEVICE_SIZES.DESKTOP_XL,
  },
  // minWidth: 1600, maxWidth: 1919
  DESKTOP_LG: {
    minWidth: DEVICE_SIZES.DESKTOP_LG,
    maxWidth: DEVICE_SIZES.DESKTOP_XL - 1,
  },
  // minWidth: 1440, maxWidth: 1599
  DESKTOP_MD: {
    minWidth: DEVICE_SIZES.DESKTOP_MD,
    maxWidth: DEVICE_SIZES.DESKTOP_LG - 1,
  },
  // minWidth: 1280, maxWidth: 1439
  DESKTOP_SM: {
    minWidth: DEVICE_SIZES.DESKTOP_SM,
    maxWidth: DEVICE_SIZES.DESKTOP_MD - 1,
  },
  // minWidth: 1280, maxWidth: 1439, orientation: 'landscape',
  DESKTOP_SM_LANDSCAPE: {
    minWidth: DEVICE_SIZES.DESKTOP_SM,
    maxWidth: DEVICE_SIZES.DESKTOP_MD - 1,
    orientation: 'landscape',
  },
  // minWidth: 1024, maxWidth: 1279
  TABLET_LG: {
    minWidth: DEVICE_SIZES.TABLET_MD,
    maxWidth: DEVICE_SIZES.DESKTOP_SM - 1,
  },
  // minWidth: 1024, maxWidth: 1439, orientation: 'portrait'
  TABLET_LG_PORTRAIT: {
    minWidth: DEVICE_SIZES.TABLET_MD,
    maxWidth: DEVICE_SIZES.DESKTOP_MD - 1,
    orientation: 'portrait',
  },
  // minWidth: 1024, maxWidth: 1279, orientation: 'landscape'
  TABLET_LG_LANDSCAPE: {
    minWidth: DEVICE_SIZES.TABLET_MD,
    maxWidth: DEVICE_SIZES.DESKTOP_SM - 1,
    orientation: 'landscape',
  },
  // minWidth: 768, maxWidth: 1023
  TABLET_MD: {
    minWidth: DEVICE_SIZES.TABLET_SM,
    maxWidth: DEVICE_SIZES.TABLET_MD - 1,
  },
  // minWidth: 768, maxWidth: 1023, orientation: 'portrait'
  TABLET_MD_PORTRAIT: {
    minWidth: DEVICE_SIZES.TABLET_SM,
    maxWidth: DEVICE_SIZES.TABLET_MD - 1,
    orientation: 'portrait',
  },
  // minWidth: 576, maxWidth: 767
  TABLET_SM: {
    minWidth: DEVICE_SIZES.MOBILE_LG,
    maxWidth: DEVICE_SIZES.TABLET_SM - 1,
  },
  // minWidth: 576, maxWidth: 767, orientation: 'portrait'
  TABLET_SM_PORTRAIT: {
    minWidth: DEVICE_SIZES.MOBILE_LG,
    maxWidth: DEVICE_SIZES.TABLET_SM - 1,
    orientation: 'portrait',
  },
  // minWidth: 480, maxWidth: 575
  MOBILE_LG: {
    minWidth: DEVICE_SIZES.MOBILE_MD,
    maxWidth: DEVICE_SIZES.MOBILE_LG - 1,
  },
  // minWidth: 480, maxWidth: 575, orientation: 'portrait'
  MOBILE_LG_PORTRAIT: {
    minWidth: DEVICE_SIZES.MOBILE_MD,
    maxWidth: DEVICE_SIZES.MOBILE_LG - 1,
    orientation: 'portrait',
  },
  // minWidth: 480, maxWidth: 1023, orientation: 'landscape'
  MOBILE_LG_LANDSCAPE: {
    minWidth: DEVICE_SIZES.MOBILE_MD,
    maxWidth: DEVICE_SIZES.TABLET_MD - 1,
    orientation: 'landscape',
  },
  // minWidth: 390, maxWidth: 479
  MOBILE_MD: {
    minWidth: DEVICE_SIZES.MOBILE_SM,
    maxWidth: DEVICE_SIZES.MOBILE_MD - 1,
  },
  // minWidth: 321, maxWidth: 389
  MOBILE_SM: {
    minWidth: DEVICE_SIZES.MOBILE_XS + 1,
    maxWidth: DEVICE_SIZES.MOBILE_SM - 1,
  },
  // maxWidth: 320
  MOBILE_XS: {
    maxWidth: DEVICE_SIZES.MOBILE_XS,
  },
};
